import React from "react"
import { Row, Col } from "antd"
import LayoutApp from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <LayoutApp>
    <SEO />
    <Row align="middle" justify="center" type="flex">
      <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: 'center'}}>
        <img alt="Logo Puratos Italia" src="/images/logo.png" width="200px" />
      </Col>
    </Row>
    <Row align="middle" justify="center" type="flex">
      <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: 'center' }}>
        <h1>MyPuratos App</h1>
        <img  alt="MyPuratos App" src="/images/slider1.jpg" width="300px" className="image-slider" style={{ borderRadius: 20 }} />
      </Col>
      <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: 'center' }}>
        <h3>Scarica l'app</h3>
        <a href="https://play.google.com/store/apps/details?id=com.teknomaintsistemi.mypuratos" _target="_blank" rel="noreferrer" style={{ marginRight: 10 }}><img alt="Google Play Store" src="/images/play-store.png" width="150px" /></a>
        <a href="https://itunes.apple.com/us/app/mypuratos/id1194158359?mt=8" _target="_blank" rel="noreferrer"><img alt="App Store" src="/images/apple-store.jpg" width="150px" /></a>
      </Col>
    </Row>

  </LayoutApp>
)

export default IndexPage
